.btn {
  @apply inline-block text-center text-lg bg-white hover:bg-gray-600 py-4 px-8 rounded-full text-gray-600 hover:text-white transform transition duration-200;
}

.btn-blue {
  @apply border border-blue-darker bg-blue-darker text-white hover:bg-white hover:text-blue-darker;
}

.btn-blue-invert {
  @apply border border-blue-darker bg-white text-blue-darker hover:border-blue-darker hover:bg-blue-darker hover:text-white;
}

.btn-purple {
  @apply border border-purple-dark bg-purple-dark text-white hover:bg-white hover:text-purple-dark;
}

.btn-purple-invert {
  @apply border border-purple-dark bg-white text-purple-dark hover:border-purple-dark hover:bg-purple-dark hover:text-white;
}

.btn-orange {
  @apply border border-orange bg-orange text-white hover:bg-white hover:text-orange;
}

.btn-orange-invert {
  @apply border border-orange bg-white text-orange hover:border-orange hover:bg-orange hover:text-white;
}

.btn-green {
  @apply border border-green bg-green text-white hover:bg-white hover:text-green;
}

.btn-green-invert {
  @apply border border-green bg-white text-green hover:border-green hover:bg-green hover:text-white;
}

.btn-transparent {
  @apply bg-transparent border border-white hover:bg-white text-white hover:text-gray-600;
}
