@font-face {
  font-family: "Roboto Condensed";
  src: url('../../fonts/RobotoCondensed-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url('../../fonts/RobotoCondensed-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url('../../fonts/RobotoCondensed-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url('../../fonts/RobotoCondensed-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Condensed";
  src: url('../../fonts/RobotoCondensed-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

html {
  @apply text-gray-600;
}
